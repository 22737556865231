import React, { createElement } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Fixed, Box, NavLink, Flex, Image, Text } from 'rebass'
import styled from 'styled-components'
import { Heading } from 'rebass/dist/Heading'
import marksy from 'marksy'

import { BLOCKS } from '@contentful/rich-text-types'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

import renderHTML from 'react-render-html'

Array.prototype.keySort = function(key, desc) {
  this.sort(function(a, b) {
    var result = desc ? a[key] < b[key] : a[key] > b[key]
    return result ? 1 : -1
  })
  return this
}

const compile = marksy({
  createElement,
  elements: {
    h2({ children }) {
      return (
        <Heading
          is="h2"
          fontSize={[1, 3]}
          fontWeight="normal"
          mb={['18px', '26px']}
        >
          {children}
        </Heading>
      )
    },
    h3({ children }) {
      return (
        <Heading
          is="h3"
          fontSize={[1, 2]}
          fontWeight="normal"
          mb={['10px', '18px']}
        >
          {children}
        </Heading>
      )
    },
    p({ children }) {
      return (
        <Text
          is="p"
          fontSize={[2, 4]}
          mb={[1]}
          css={`
            line-height: 1.57;
          `}
        >
          {children}
        </Text>
      )
    },
    ul({ children }) {
      return (
        <Box is="ul" pl={[18, 40]} fontSize={[0, 1]} mb={[16]}>
          {children}
        </Box>
      )
    },
    ol({ children }) {
      return (
        <Box is="ol" pl={[18, 40]} fontSize={[0, 1]} mb={[16]}>
          {children}
        </Box>
      )
    },
    li({ children }) {
      return (
        <Text
          is="li"
          fontSize={[0, 1]}
          mb={[1, 8]}
          style={{
            listStyle: 'circle',
          }}
        >
          {children}
        </Text>
      )
    },
    a({ href, title, target, children }) {
      return (
        <a
          href={href}
          title={title}
          target={'_blank'}
          style={{ color: '#f40066' }}
        >
          {children}
        </a>
      )
    },
    blockquote({ children }) {
      return (
        <Text
          is="blockquote"
          className="blockquote"
          css={`
            p {
              font-size: 35px;
              line-height: 1.46;
              text-align: center;
              margin-top: 82px;
              margin-bottom: 76px;
              color: #f40066;

              @media (max-width: 767px) {
                font-size: 18px;
                margin: 40px 0;
              }
            }
          `}
        >
          {children}
        </Text>
      )
    },
    img({ src, alt }) {
      // as it's only those 3 formats, it's pretty straigthforward, but we could use whatever number of formats
      const regex = /.mp4|.ogg|.webm/g
      const isVideo = src.match(regex)

      if (isVideo) {
        return (
          <React.Fragment>
            <Box
              is="video"
              src={src}
              alt={alt}
              style={{
                width: '100%',
                maxWidth: '100%',
              }}
              mt={['19px', '44px']}
              mb={['13px', '16px']}
              controls
            >
              <source src={src} />
            </Box>
            <Text
              is="span"
              fontSize={[0]}
              pb={['35px', '54px']}
              color="coolGrey"
              textAlign="right"
              style={{ display: 'block' }}
            >
              {alt}
            </Text>
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <Image
              src={src}
              alt={alt}
              style={{
                maxWidth: '100%',
              }}
              mt={['19px', '44px']}
              mb={['13px', '16px']}
            />
            <Text
              is="span"
              fontSize={[0]}
              pb={['35px', '54px']}
              color="coolGrey"
              textAlign="right"
              style={{ display: 'block' }}
            >
              {alt}
            </Text>
          </React.Fragment>
        )
      }
    },
  },
})

const Post = props => {
  const windowGlobal = typeof window !== 'undefined' && window
  let d = props.data

  if (d.length > 0) {
    d = d.keySort('createdAt').reverse()

    const parallax = () => {
      windowGlobal.onscroll = () => {
        const speed = 25

        let x = [],
          i = 1,
          endInt = document.querySelectorAll('.background').length

        while (i <= endInt) {
          x.push(i)
          i++
        }

        document.querySelectorAll('.background').forEach((k, i) => {
          x = x.reverse()
          i = x[i]

          k.style.backgroundPosition =
            -windowGlobal.pageXOffset / (speed / i) +
            'px ' +
            -windowGlobal.pageYOffset / (speed / i) +
            'px'
        })
      }
    }

    // parallax()

    return (
      <>
        <Flex
          color="white"
          py={5}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
        >
          {d.map((k, i) => {
            const markdown = k.text ? k.text.markdown : ''
            const markdownBody = markdown ? markdown.rawMarkdownBody : ''
            const compiled = compile(markdownBody || '', {
              sanitize: true,
            })

            if (props.type === 'cassettes') {
              return (
                <Flex key={i} my={1} width={1} flexWrap={'wrap'}>
                  <Box width={[1, 0.3]}>
                    <img
                      src={k.cover ? k.cover.file.url : ''}
                      style={{ width: '100%' }}
                    />
                  </Box>
                  <Flex
                    width={[1, 0.7]}
                    flexWrap={'inherit'}
                    alignItems={'center'}
                    pl={4}
                    className="background"
                    css={`
                      position: relative;
                      align-items: center;
                    `}
                  >
                    <Heading
                      is="h3"
                      mb={2}
                      css={`
                        top: 0;
                      `}
                    >
                      {k.titre}
                    </Heading>
                    <Box
                      css={`
                        bottom: 0;
                        width: 100%;
                      `}
                    >
                      <audio
                        controls
                        preload="none"
                        download
                        style={{ width: '100%', cursor: 'pointer' }}
                      >
                        <source src={k.file.file.url} type="audio/ogg" />
                        <source src={k.file.file.url} type="audio/mpeg" />
                        Your browser does not support the audio tag.
                      </audio>
                    </Box>
                  </Flex>
                </Flex>
              )
            } else {
              return (
                <Box key={i} my={1}>
                  <Box key={i} mb={2} width={1}>
                    <Heading is="h3" mb={2}>
                      {k.titre}
                    </Heading>

                    <Image
                      src={k.cover ? k.cover.file.url : ''}
                      alt={k.cover ? k.cover.file.fileName : ''}
                      my={4}
                      width={'100%'}
                    />

                    <Box mb={4}>
                      {renderHTML(documentToHtmlString(k.betterText))}
                    </Box>
                  </Box>
                </Box>
              )
            }
          })}
        </Flex>
      </>
    )
  }
}

export default Post
