module.exports = {
  /* MUST HAVES */

  siteUrl: 'https://martindebray.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  shortUrl: 'martindebray.com',
  siteTitle: 'martin debray',
  siteDescription: 'The best new website on the internet!',

  author: 'martin debray', // Author for RSS author segment and SEO schema
  authorUrl: 'htts://18.166.98.78', // URL used for author and publisher schema, can be a social profile or other personal site

  copyright: 'martindebray.com © 2018 ', // Copyright string for the RSS feed

  /* Schema */

  siteTitleAlt: 'martin debray', // This allows an alternative site title for SEO schema.
  shortTitle: 'md', // Used for App manifest e.g. Mobile Home Screen
  publisher: 'martin debray', // Organization name used for SEO schema

  /* SEO Critical */

  userTwitter: '@martindebray_II', // Change for Twitter Cards
  shareImage: '/logos/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/logos/logo-512.png', // Logo used for SEO, RSS, and App manifest

  /* PWA/OFFLINE SETTINGS */

  backgroundColor: require('./../styles/theme.js').colors.backgroundColor, // Used for Offline Manifest
  themeColor: require('./../styles/theme.js').colors.themeColor, // Used for Offline Manifest
}
