import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Fixed, Box, NavLink, Flex } from 'rebass'
import styled from 'styled-components'

const links = [
  {
    path: '/',
    title: 'Accueil',
  },
  {
    path: '/brouillon-neutre',
    title: 'Brouillon Neutre',
  },
  {
    path: '/cassettes',
    title: 'Cassettes',
  },
]

const Link = styled(NavLink)`
  width: calc(100% / ${links.length});
  text-align: center;
  display: inline-block;
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes[5]}px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;

  &:hover,
  &.active {
    color: ${props => props.theme.colors.backgroundColor};
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
  }

  @media (max-width: 400px) {
    width: 100%;
  }
`

const Menu = () => {
  return (
    <>
      <Fixed
        top={0}
        left={0}
        right={0}
        pt={[0, 2]}
        css={`
          @media (max-width: 400px) {
            position: relative;
          }
        `}
      >
        <Box color="white">
          {links.map((k, i) => {
            return (
              <Link
                key={i}
                is={GatsbyLink}
                to={k.path}
                activeClassName="active"
              >
                {k.title}
              </Link>
            )
          })}
        </Box>
      </Fixed>
    </>
  )
}

Menu.Pad = () => (
  <Box
    py={2}
    css={`
      @media (max-width: 400px) {
        display: none;
      }
    `}
  >
    <NavLink>&nbsp;</NavLink>
  </Box>
)

export default Menu
