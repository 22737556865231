module.exports = {
  fonts: {
    title: 'Niramit, sans-serif',
    sans: 'Helvetica, Arial, sans-serif',
    mono: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72, 96],
  fontWeights: {
    normal: 400,
    bold: 700,
  },
  colors: {
    /* Required: For PWA/Offline Settings */
    backgroundColor: '#000',
    themeColor: '#fff',
    pink: '#f40066',
  },
  radii: [0, 2, 4],
}
