import { injectGlobal } from 'styled-components'

injectGlobal`
  @import url('https://fonts.googleapis.com/css?family=Niramit:400,700');

  * { box-sizing: border-box; }

  body {
    margin: 0;
    background: black;
  }

  .play-pause {
    display: block;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 20px;
    height: 20px;
    color: #222;
    background-color: #f06;
    cursor: pointer;
    overflow: hidden;
    background-image: linear-gradient(to right, transparent 40%, currentColor 40%, currentColor 60%, transparent 0);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transition: background-size .3s;
  }

  .play-pause::before,
  .play-pause::after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: currentColor;
    transform: skew(90deg);
    transform-origin: top left;
    transition: transform .3s;
  }

  .play-pause::after {
    transform-origin: bottom left;
  }

  .paused {
    .play-pause {
      background-size: 0 100%;
    }

    .play-pause::before {
      transform: skew(64deg);
    }

    .play-pause::after {
      transform: skew(117deg);
    }
  }

  .sep {
    background: red;
    width: 50px;
    height: 50px;
  }
`
