import React, { createElement } from 'react'
import { graphql } from 'gatsby'
import marksy from 'marksy'
import { Box, Heading, Text, Image, Link } from 'rebass'
import styled, { keyframes } from 'styled-components'
import { TweenMax, Power0 } from 'gsap'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Post from '../components/Post'

import meInverted from '../assets/images/me_inverted.jpg'
import vidzMp4 from '../assets/videos/me.mp4'
import vidzWebm from '../assets/videos/me.webm'
import vidzOgv from '../assets/videos/me.mp4'

const MyLink = styled(Link)`
  color: white;
  fontweight: 700;

  &:hover {
    color: ${props => props.theme.colors.pink};
  }
`

const Sep = styled(Box)``

const compile = marksy({
  createElement,
  elements: {
    hr({ children }) {
      return <Sep my={[2, 5]} />
    },
    h2({ children }) {
      return (
        <Heading
          is="h2"
          fontSize={[1, 3]}
          fontWeight="normal"
          mb={['18px', '26px']}
        >
          {children}
        </Heading>
      )
    },
    h3({ children }) {
      return (
        <Heading
          is="h3"
          fontSize={[1, 2]}
          fontWeight="normal"
          mb={['10px', '18px']}
        >
          {children}
        </Heading>
      )
    },
    p({ children }) {
      return (
        <Text
          is="p"
          fontSize={[2, 4]}
          my={[2]}
          css={`
            line-height: 1.57;
          `}
        >
          {children}
        </Text>
      )
    },
    ul({ children }) {
      return (
        <Box is="ul" pl={[18, 40]} fontSize={[0, 1]} mb={[16]}>
          {children}
        </Box>
      )
    },
    ol({ children }) {
      return (
        <Box is="ol" pl={[18, 40]} fontSize={[0, 1]} mb={[16]}>
          {children}
        </Box>
      )
    },
    li({ children }) {
      return (
        <Text
          is="li"
          fontSize={[0, 1]}
          mb={[1, 8]}
          style={{
            listStyle: 'circle',
          }}
        >
          {children}
        </Text>
      )
    },
    a({ href, title, target, children }) {
      return (
        <MyLink href={href} title={title} target={'_blank'}>
          {children}
        </MyLink>
      )
    },
    blockquote({ children }) {
      return (
        <Text
          is="blockquote"
          className="blockquote"
          css={`
            p {
              font-size: 35px;
              line-height: 1.46;
              text-align: center;
              margin-top: 82px;
              margin-bottom: 76px;
              color: #f40066;

              @media (max-width: 767px) {
                font-size: 18px;
                margin: 40px 0;
              }
            }
          `}
        >
          {children}
        </Text>
      )
    },
    img({ src, alt }) {
      // as it's only those 3 formats, it's pretty straigthforward, but we could use whatever number of formats
      const regex = /.mp4|.ogg|.webm/g
      const isVideo = src.match(regex)

      if (isVideo) {
        return (
          <React.Fragment>
            <Box
              is="video"
              src={src}
              alt={alt}
              style={{
                width: '100%',
                maxWidth: '100%',
              }}
              mt={['19px', '44px']}
              mb={['13px', '16px']}
              controls
            >
              <source src={src} />
            </Box>
            <Text
              is="span"
              fontSize={[0]}
              pb={['35px', '54px']}
              color="coolGrey"
              textAlign="right"
              style={{ display: 'block' }}
            >
              {alt}
            </Text>
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <Image
              src={src}
              alt={alt}
              style={{
                maxWidth: '100%',
              }}
              mt={['19px', '44px']}
              mb={['13px', '16px']}
            />
            <Text
              is="span"
              fontSize={[0]}
              pb={['35px', '54px']}
              color="coolGrey"
              textAlign="right"
              style={{ display: 'block' }}
            >
              {alt}
            </Text>
          </React.Fragment>
        )
      }
    },
  },
})

const rotate360 = keyframes`
  from {
    transform: translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateY(-50%) rotate(360deg);
  }
`

const Me = styled(Box)`
  position: fixed;
  top: 50%;
  left: 0;
  width: 100%;
  height: auto;
  opacity: 0.666;
  z-index: -1;
  transform: translateY(-50%);

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  @keyframes colors {
    0% {
      filter: hue-rotate(0deg);
    }
    100% {
      filter: hue-rotate(360deg);
    }
  }

  @media (max-width: 768px) {
    position: initial;
    opacity: 1;
    transform: none;

    &::after {
      background: rgba(0, 0, 0, 0);
    }

    video {
      /* display: none; */
    }

    img {
      display: initial !important;
      width: 100%;
      height: 100%;
      object-fit: cover;
      animation: colors 18s infinite linear;
    }
  }
`

const filterIframe = (str, { tagname = 'iframe', replaceWith = '' } = {}) => {
  const regexp = new RegExp(`<${tagname}(.|\n)*?</${tagname}>`, 'gim')

  const wrapFrame = `<div className="iframe-container" key="${Math.random() *
    10}">${str.match(regexp)}</div>`

  return str.replace(regexp, wrapFrame)
}

const SectionsTemplate = ({ props, data }) => {
  const d = data.page

  const markdown = d.body ? d.body.markdown : ''
  const markdownBody = markdown ? markdown.rawMarkdownBody : ''
  const compiled = compile(markdownBody ? filterIframe(markdownBody) : '')
  let postData = ''
  let postType = ''

  if (d.chronique) {
    postData = 'chronique'
    postType = d.chronique
  } else if (d.cassettes) {
    postData = 'cassettes'
    postType = d.cassettes
  }

  let modulesData = postType ? (
    <Post key={Math.random()} type={postData} data={postType} />
  ) : (
    ''
  )

  const rotate = e => {
    setTimeout(() => {
      const _document = typeof window !== 'undefined' && window.document

      _document
        ? TweenMax.to(_document.getElementById('itsameVideo'), 666, {
            rotation: 360,
            ease: Power0.easeNone,
            repeat: -1,
          })
        : ''
    })
  }

  return (
    <Layout>
      <SEO title={d.title} description={markdown.excerpt} pageUrl={d.slug} />
      <Box is="article">
        <Box>
          <Heading
            is="h1"
            fontSize={[4, 8]}
            fontWeight="700"
            fontFamily="title"
            mb={4}
          >
            {d.title}
          </Heading>

          <Box mb={0}>{compiled ? compiled.tree : 'ERROR FETCHING DATA'}</Box>

          {modulesData}
        </Box>

        <Me id="itsame" mt={[5]}>
          <video
            id="itsameVideo"
            width="100%"
            height="100%"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            playsInline
            muted
            autoPlay
            loop
            poster={meInverted}
            onLoad={rotate()}
          >
            <source src={vidzMp4} type="video/mp4" />
            <source src={vidzWebm} type="video/webm" />
            <source src={vidzOgv} type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </Me>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      contentful_id
      title
      slug
      body {
        markdown: childMarkdownRemark {
          html
          excerpt
          rawMarkdownBody
        }
      }
      cassettes {
        createdAt
        titre
        file {
          file {
            url
          }
        }
        cover {
          id
          file {
            url
            fileName
            contentType
          }
        }
      }
      chronique {
        createdAt
        titre
        cover {
          id
          file {
            url
            fileName
            contentType
          }
        }
        betterText {
          nodeType
          content {
            nodeType
            content {
              nodeType
              value
              marks {
                type
              }
            }
          }
        }
      }
    }
  }
`

export default SectionsTemplate
